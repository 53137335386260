// Exported reusable functions to make the code DRY

// NOTE:  A setter method ALWAYS has access to the current value of state if you 
//		use an arrow function and get the current value as a parameter

export const newWaitlistAdded = (data, setWaitlist, componentName) => {
	console.log(`${componentName} new_waitlist_added received by client`);
	// the data we receive is the entire waitlist object
	console.log(data);

	setWaitlist( (waitlist) => [...waitlist, data]);
}

export const oneWaitlistDeleted = (data, setWaitlist, componentName) => {
	// group the console logs so they are easy to work with in the console
	console.group();

	console.log(`${componentName} one_waitlist_deleted received by client`);

	// the data we receive is the entire waitlist object
	console.log(data);

	setWaitlist((currentWaitlist) => {
			return currentWaitlist.filter(waitlistObj => {
					console.log(`waitlist_id: ${waitlistObj._id}`)
					return waitlistObj._id !== data._id;
			})
	});

	console.groupEnd();
}

// following doesn't work, needs to be fixed
export const oneWaitlistUpdated = (data, setWaitlist, componentName) => {
	// group the console logs so they are easy to work with in the console
	console.group();

	console.log(`${componentName} one_waitlist_updated received by client`);

	// the data we receive is the entire waitlist object
	console.log(data);

	setWaitlist( (waitlist) => [...waitlist, data]);

	console.groupEnd();
}