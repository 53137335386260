// Exported reusable functions to make the code DRY

export const removeFromWaitlistState = (waitlistId, setWaitlist, componentName) => {
	console.group();
	console.log(`${componentName} - ID to remove: ${waitlistId}`)
	setWaitlist((waitlist) => waitlist.filter(waitlistObj => {
			console.log(`waitlist_id: ${waitlistObj._id}`)
			return waitlistObj._id !== waitlistId
	}));
	console.groupEnd();
}