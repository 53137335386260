import React, { useEffect, useState } from 'react';
import { navigate, Link } from '@reach/router';
import axios from 'axios';
import io from 'socket.io-client';
import moment from 'moment';
import DeleteButton from './DeleteButton';
import SMSButton from './SMSButton';
import {newWaitlistAdded, oneWaitlistDeleted, oneWaitlistUpdated} from '../reusable/socket';
import {removeFromWaitlistState} from '../reusable/dom';

const WaitlistAdmin = (props) => {
    const [waitlist, setWaitlist] = useState([]);
    const [restaurant, setRestaurant] = useState({});
    const [wtime, setWtime] = useState();
    const [rname, setRname] = useState();
    const [rphone, setRphone] = useState();
    const [id, setId] = useState([]);
    const [confirmMsg, setConfirmMsg] = useState();

    var tableReady = false;
    // const { waitlist, setWaitlist } = props;

    // save our socket connection in state so that it stays connected until
    // we leave or refresh the page
    const [socket] = useState(() => {
        // console.log("create socket in WaitlistAdmin.js");
        return io();
    });  // once socket created we don't reset socket so no need for setSocket

    useEffect(() => {
        console.log("inside useEffect - sockets - WaitlistAdmin");
        socket.on("connect", () => {
            // console.log(`Connect on the client with ID: ${socket.id}`);
        })

        socket.on("new_waitlist_added", (data) => {
            // Since this uses the exact same code as waitlist, I added it to a reusable functions file
            //      that way our code can remain DRY
            newWaitlistAdded(data, setWaitlist, "WaitlistAdmin");
        })

        socket.on("one_waitlist_deleted", (data) => {
            oneWaitlistDeleted(data, setWaitlist, "WaitlistAdmin")
        })

        socket.on("one_waitlist_updated", (data) => {
            // console.log("one_waitlist_updated received by client");
            // console.log(data);
            oneWaitlistUpdated(data, setWaitlist, "WaitlistAdmin")
            // we need to create functionality similar to one_waitlist_deleted and use it here
            window.location.reload(true);
        })

        // close socket connection when component is done being used
        return () => {
            // console.log("exiting WaitlistAdmin - socket disconnect")
            return socket.disconnect();
        }

    },[socket])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_DOMAIN_URL}/api/waitlistadmin`, { withCredentials: true })
            .then(res => {
                setWaitlist(res.data);
            })
            .catch(err => {
                console.log(err);
                if(err.response.status === 401){
                    navigate("/login");
                }
            });
    },[])

    useEffect(() => {
        axios.get('/api/restaurant')
        .then(res => {
            // console.log("get restaurant useEffect - Restaurant");
            setRestaurant(res.data[0]);
            // console.log(restaurant);
            setWtime(res.data[0].wtime);
            setId(res.data[0]._id);
            setRname(res.data[0].rname);
            setRphone(res.data[0].rphone);
        });

    }, [])
    
    // const { removeFromDom } = props;
    
    // const deleteWaitlist = (waitlistId) => {
    //     axios.delete(`${process.env.REACT_APP_DOMAIN_URL}/api/waitlist/` + waitlistId)
    //         .then(res => {
    //             removeFromDom(waitlistId);
    //         })
    // }
    
    const updateWtime = (e) => {
        e.preventDefault();
        try {
            restaurant.wtime = wtime;
        }
        catch {}
        axios.put('/api/restaurant/' + id, restaurant, { withCredentials: true })
            .then(res => {
                // notify the server through socket.io to share this create with other clients
                // console.log('in update time axios put');
                // console.log(res.data);
                socket.emit("updated_wait_time", res.data);
                // console.log("update wait time success");
                setConfirmMsg(`Estimated wait time updated to ${wtime} minutes`);
                // navigate("/admin");
            })
            .catch(err => {
                console.log(err.res.data.errors);
                // setErrors(e.response.data.errors);
            })
    }
    
    const refreshWaitlist = () => {
        window.location.reload(true);
    }

    return (
        <div>
            <table class="table">
                <thead>
                    <tr>
                        <td>
                            <form class="form-inline">
                            <div class="form-group">
                                <label for="wtime" class="col-sm-5 control-label">Current wait time (minutes):</label>
                                <div class="col-sm-5">
                                    <input type="number" class="form-control" id="wtime" value={wtime} placeholder="Minutes" onChange = {(event)=>setWtime(event.target.value)}/>
                                </div>
                                <button class="btn btn-info" onClick={ updateWtime }>Update</button>
                            </div>
                            </form>
                        </td>
                        { <td>
                            <button class="btn btn-success" onClick={ refreshWaitlist }>Refresh Waitlist</button>
                        </td> }
                    </tr>
                </thead>
            </table>
            {
                confirmMsg ? 
                <h4 style={{color: "green"}}>{confirmMsg}</h4>
                : null
            }

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Size</th>
                        <th>Added</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
            {waitlist.map((waitlistObj, idx) => {
                return (
                    <tr key={idx}>
                        <td>{waitlistObj.cname}</td>
                        <td><a href={"tel:" + waitlistObj.cphone}>{ waitlistObj.cphone }</a></td>
                        <td>{waitlistObj.size}</td>
                        <td>{moment(waitlistObj.createdAt).format('h:mm A')}</td>
                        <td>{ waitlistObj.ready?
                                <span class="blink">Table Ready</span>
                                :<p>Waiting</p>
                            }
                        </td>
                        {<td>
                            {/* <SMSButton waitlistId={ waitlist._id } waitlistPhone={ waitlist.cphone } waitlist={ waitlist } setWaitlist={ setWaitlist } successCallback={() => window.location.reload(true)}/> */}
                            {/* Send in an updated copy of the object so it is ready to be sent with axios right away          */}
                            {/*     This will allow us to use the same SMS button component for all types of status updates    */}
                            <SMSButton socket={socket} waitlistId={ waitlistObj._id } waitlistObj={ {...waitlistObj, ready: true} } successCallback={() => window.location.reload(true)} btnText={"Ready"}/>
                            {/* &nbsp;&nbsp;<button type="button" class="btn btn-info"><Link to={ "/home/" + waitlistObj._id } style={{ color: "white", textDecoration: "none" }}>Details</Link></button> */}
                            {/* &nbsp;&nbsp;<SMSButton socket={socket} waitlistId={ waitlistObj._id } waitlistObj={ {...waitlistObj, ready: false} } successCallback={() => window.location.reload(true)} btnText={"Not ready"}/> */}
                            &nbsp;&nbsp;<button type="button" class="btn btn-info"><Link to={ "/home/" + waitlistObj._id + "/edit" } style={{ color: "white", textDecoration: "none" }}>Edit</Link></button>
                            {/* &nbsp;&nbsp;<DeleteButton waitlistId={ waitlist._id } successCallback={()=> navigate("/admin")}/> */}
                            {/* &nbsp;&nbsp;<DeleteButton waitlistId={ waitlist._id } successCallback={()=> window.location.reload(true)}/> */}
                            &nbsp;&nbsp;<DeleteButton waitlistId={ waitlistObj._id } socket={socket} successCallback={()=> removeFromWaitlistState(waitlistObj._id, setWaitlist, "WaitlistAdmin")}/>
                        </td>}
                    </tr>
            )})}
            </tbody>
            </table>
            <hr/>
        </div>
    )
}

export default WaitlistAdmin;
