import React from 'react';
// import io from 'socket.io-client';
import axios from 'axios';
// import { navigate } from '@reach/router';

const SMSButton = (props) => {
    const { socket, waitlistObj, waitlistId, successCallback, btnText } = props;
    // use the existing socket from the parent to prevent a new socket connection
    //      for every time we create a new button

    const updateWaitlist = () => {
        axios.put('/api/sms/' + waitlistId, waitlistObj, { withCredentials: true })
            .then(res => {
                // notify the server through socket.io to share this create with other clients
                socket.emit("updated_one_waitlist", res.data);
                
                // DO NOT disconnect now - we are using the Parent Component's socket connection

                // after emit, disconnect socket to free up resources
                // socket.disconnect();
                successCallback()})
                // navigate("/admin")})
            .catch(err => {
                console.log(err.response.data.errors);
                // setErrors(err.response.data.errors);
            })
        }
    
    // const notifySMS = (waitlistObj) => {
    //     setWaitlist(waitlist.ready = true);
    //     updateWaitlist(updatedWaitlistObj);
    //     // updateWaitlist is going to call the success callback function and so this will never run
    //     // navigate("/admin");
    // }
    
    return (
            <button className="btn btn-success" onClick={ updateWaitlist }>{btnText}</button>
    )
}
export default SMSButton;