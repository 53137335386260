import React, { useEffect, useState } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import moment from 'moment';
import {newWaitlistAdded, oneWaitlistDeleted, oneWaitlistUpdated} from '../reusable/socket';

const Waitlist = (props) => {
    const [waitlist, setWaitlist] = useState([]);

    // save our socket connection in state so that it stays connected until
    // we leave or refresh the page
    const [socket] = useState(() => {
        // console.log("create socket in Waitlist.js");
        return io();
    });  // once socket created we don't reset socket so no need for setSocket

    useEffect(() => {
        // console.log("inside useEffect - sockets - Waitlist");

        socket.on("connect", () => {
            // console.log(`Connect on the client with ID: ${socket.id}`);
        })

        socket.on("new_waitlist_added", (data) => {
            // Since this uses the exact same code as WaitlistAdmin, I added it to a reusable functions file
            //      that way our code can remain DRY
            newWaitlistAdded(data, setWaitlist, "Waitlist");
        })

        socket.on("one_waitlist_deleted", (data) => {
            oneWaitlistDeleted(data, setWaitlist, "Waitlist")
        })

        socket.on("one_waitlist_updated", (data) => {
            // console.log("one_waitlist_updated received by client");
            // console.log(data);
            // oneWaitlistUpdated(data, setWaitlist, "Waitlist")
            window.location.reload(true);
        })

        socket.on("wait_time_updated", (data) => {
            // console.log("wait_time_updated received by client");
            // console.log(data);
            // setRestaurant(data);
            window.location.reload(true);
        })

        // close socket connection when component is done being used
        return () => {
            // console.log("exiting Waitlist - socket disconnect")
            return socket.disconnect();
        }

    },[socket])

    useEffect(() => {
        axios.get('/api/waitlist')
            .then(res => {
                // console.log("get waitlist useEffect - Waitlist");
                setWaitlist(res.data)
            });
    },[])

    const refreshWaitlist = (() => {
        window.location.reload(true);
    })

    return (
        <div>
            {/* <button class="btn btn-success" onClick={ refreshWaitlist }>Refresh Waitlist</button> */}
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Size</th>
                        <th>Added</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
            {waitlist.map((waitlist, idx) => {
                return (
                    <tr key={idx}>
                        <td>{waitlist.maskedName}*</td>
                        <td>*{waitlist.maskedPhone}</td>
                        <td>{waitlist.size}</td>
                        <td>{moment(waitlist.createdAt).format('h:mm A')}</td>
                        <td>{ waitlist.ready?
                                <span class="blink">Table Ready</span>
                                :<p>Waiting</p>
                            }
                        </td>
                    </tr>
            )})}
            </tbody>
            </table>
            <hr/>
        </div>
    )
}

export default Waitlist;
