import './App.css';
import React, {useState} from 'react';
import { Router } from '@reach/router';
import Main from './views/Main';
// import Admin from './views/Admin';
import WaitlistAdmin from './components/WaitlistAdmin';
// import Detail from './views/Detail';
import Update from './views/Update';
import NavBar from './components/NavBar';
import New from './views/New';
import LogReg from './views/LogReg';
// import Login from './components/Login';


function App() {

  const [reloadBoolean, setReloadBoolean] = useState(false);
  return (
    <div class="container">
      <NavBar setReloadBoolean={setReloadBoolean} reloadBoolean={reloadBoolean}/>
      <Router>
        {/* <LogReg path="/" /> */}
        <Main default/>
        <WaitlistAdmin path="/admin"/>
        <LogReg setReloadBoolean={setReloadBoolean} reloadBoolean={reloadBoolean} path="/login" />
        {/* <Detail path="/home/:id"/> */}
        <Update path="/home/:id/edit"/>
        <New path="/home/new"/>
      </Router>
    </div>
  );
}

export default App;
