import React, { useState, useEffect } from 'react';
import axios from 'axios';
import WaitlistForm from '../components/WaitlistForm';
import io from 'socket.io-client';

const New = (props) => {
    const [waitlist, setWaitlist] = useState([]);
    const [restaurant, setRestaurant] = useState(['wtime:0']);
    const [loaded, setLoaded] = useState([false]);
    const [errors, setErrors] = useState({});
    const [confirmMsg, setConfirmMsg] = useState();
    const [socket] = useState(() => {
        console.log("create socket in New.js");
        return io();
    });  // once socket created we don't reset socket so no need for setSocket
    
    // we need to clean up the socket ONLY when we "unmount" this component
    //      it needs to be able to stay connected regardless of the number of 
    //      newWaitList objects are added

    useEffect(() => {
        console.log("inside useEffect - sockets - New");
        socket.on("connect", () => {
            console.log(`Connect on the client with ID: ${socket.id}`);
        })

        socket.on("wait_time_updated", (data) => {
            console.log("wait_time_updated received by client");
            console.log(data);
            setRestaurant(data);
            window.location.reload(true);
        })

        // close socket connection when component is done being used
        return () => {
            console.log("exiting New - socket disconnect")
            return socket.disconnect();
        }

    },[socket])

    useEffect(() => {
        axios.get('/api/restaurant')
            .then(res => {
                console.log("get restaurant useEffect - Restaurant");
                setRestaurant(res.data[0]);
            });
    },[])

    const createWaitlist = (newWaitlist) => {
        try {
            newWaitlist.cname = newWaitlist.cname.replace(/[^0-9a-zA-Z. -]/g, '');
            newWaitlist.maskedName = newWaitlist.cname.charAt(0);
            newWaitlist.cphone = newWaitlist.cphone.replace(/[^\d], ()-/g, "");
            newWaitlist.maskedPhone = newWaitlist.cphone.substr((newWaitlist.cphone.length-4),4);
            newWaitlist.size = newWaitlist.size.replace(/[^\d]/g, "");
            newWaitlist.wtime = restaurant.wtime;
        }
        catch {}
        axios.post('/api/waitlist', newWaitlist)
            .then(res=>{
                setWaitlist([...waitlist, res.data]);
                // notify the server through socket.io to share this create with other clients
                socket.emit("added_new_waitlist", res.data);

                // Since the NEW component will not unmount, we need to keep the 
                //      socket open so the next new waitlist item can still succeed
                // after emit, disconnect socket to free up resources
                // socket.disconnect();
                setConfirmMsg(`${res.data.cname}, party of ${res.data.size}, ${res.data.cphone} has been added to waitlist!  A text message has been sent to confirm`);
                // window.location.reload(true);
            })
            .catch(err => {
                console.log(err.response.data.errors);
                setErrors(err.response.data.errors);
            })
    }

    return (
        <div>
            <h3>Add name to waitlist (current estimated wait time: {restaurant.wtime} minutes)</h3><br/>
            <WaitlistForm onSubmitProp={createWaitlist}
                initialCname=""
                initialCphone=""
                initialSize=""
                buttonText="Add"
                errors={errors}/>
            {
                confirmMsg ? 
                <h4 style={{color: "green"}}>{confirmMsg}</h4>
                : null
            }

        </div>
    )
}
export default New;
