import React, { useState } from "react";
import axios from "axios";
import { navigate } from '@reach/router';

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const login = event => {
    event.preventDefault();
    axios.post("/api/users/login", { 
        email: email, 
        password: password,
      },
      {
        withCredentials: true,
      }
      )
      .then(res => {
        localStorage.setItem("userId", res.data.userId); 
        navigate("/admin");
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
      });
  };

  return (
    <div>
      <h3>Login</h3>
      <p className="error-text">{errorMessage ? errorMessage : ""}</p>
      <form class="form-horizontal" onSubmit={login}>
        <div class="form-group">
          <label class="col-sm-2 control-label">Email</label>
          <div class="col-sm-2">
          <input
            class="form-control"
            type="text"
            name="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">Password</label>
          <div class="col-sm-2">
          <input 
            class="form-control"
            type="password"
            name="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-offset-2 col-sm-10">
            <button 
              class="btn btn-primary"
              type="submit"
            >Sign In</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
