import React, {useState, useEffect} from 'react';
import { Link, navigate } from '@reach/router';
import axios from 'axios';
import logo from '../images/logo.png';

const NavBar = (props) => {

  const [currentUserId, setCurrentUserId] = useState("");
  const {reloadBoolean} = props;

  const logout = (e) => {
    e.preventDefault();
    axios.post("/api/users/logout", { 
        // no body required for this request
      }, {
        withCredentials: true,
      })
      .then((res) => {
        setCurrentUserId("");
        localStorage.removeItem("userId");
        navigate("/");
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(()=>{
    setCurrentUserId(localStorage.getItem("userId"));
  }, [reloadBoolean])

    return (
      <div class="nav">
        <br/><img src={logo} alt="Bistro 404" width="200"/>
        {/* &nbsp;&nbsp;<h1 style={{ font: "Verdana"}}>Merning Bistro</h1> */}
        <ul>
          <li><Link style={{ textDecoration: "none", fontSize: "14pt", paddingRight: "20px" }} to="/">Home</Link></li>
          <li><Link style={{ textDecoration: "none", fontSize: "14pt", paddingRight: "20px" }} to="/admin">Admin</Link></li>
          {
            currentUserId?
            <li><Link style={{ textDecoration: "none", fontSize: "14pt" }} to="/" onClick={ logout }>Logout</Link></li>
            :null
          }
        </ul>
      </div>
    );
}

export default NavBar;