import React, { useState } from 'react';
// import { Link } from '@reach/router';

const WaitlistForm = (props) => {
    
    // preserve inputs using state hooks
    const { initialCname, initialCphone, initialSize, onSubmitProp, errors } = props;
    const [cname, setCname] = useState(initialCname);
    const [cphone, setCphone] = useState(initialCphone);
    const [size, setSize] = useState(initialSize);

    const submitHandler = event => {
        // prevent fields from clearing by default
        event.preventDefault();
        onSubmitProp({ cname, cphone, size, errors });
        // only the NEW component will remain on the same page and so we can 
        //      reset state to empty strings and it will only affect NEW
        // window.location.reload(true);
        // setCname("");
        // setCphone("");
        // setSize("");
    }

    // function phoneInputComponent() {
    //     const [inputValue, setInputValue] = useState("");
      
    const handlePhoneInput = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setCphone(formattedPhoneNumber);
    };
      
    //     return <input onChange={(e) => handleInput(e)} value={inputValue} />;
    //   }

    function formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        // if (!value) return value;
        
        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");
        
        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;
        
        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
        if (phoneNumberLength >0 && phoneNumberLength < 3) return `(${phoneNumber}`;

        if (phoneNumberLength > 3 && phoneNumberLength < 6) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        
        if (phoneNumberLength > 6) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }

    const handleNameInput = (e) => {
        const formattedName = e.target.value.replace(/[^0-9a-zA-Z. -]/g, '');
        // we'll set the input value using our setInputValue
        setCname(formattedName);
    };

    return (
    <form class="form-horizontal" onSubmit={submitHandler}>
        <table>
            <tbody>
                <tr>
                    <td width="400px" valign="top">
            <div class="form-group">
                <label for="cname" class="col-sm-4 control-label">Name:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" id="cname" value={cname} placeholder="Enter your name" onChange={handleNameInput}/>
                { errors.cname ?
                    <span style={{color:"red"}}>{errors.cname.message}</span>
                    :null
                }
                </div>
            </div>
            <div class="form-group">
                <label for="cphone" class="col-sm-4 control-label">Phone (SMS):</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" id="cphone" value={cphone} placeholder="Enter phone (SMS)" onChange={handlePhoneInput}/>
                { errors.cphone ?
                    <span style={{color:"red"}}>{errors.cphone.message}</span>
                    :null
                }
                </div>
            </div>
            </td>
            <td width="400px" valign="top">
            <div class="form-group">
                <label for="size" class="col-sm-4 control-label">Party size:</label>
                <div class="col-sm-4">
                    <input type="number" class="form-control" id="size" value={size} placeholder="Number" onChange = {(event)=>setSize(event.target.value)}/>
                { errors.size ?
                    <span style={{color:"red"}}>{errors.size.message}</span>
                    :null
                }
                </div>
            </div>
            <div class="col-sm-offset-5 col-sm-5">
                <button type="submit" class="btn btn-info">{props.buttonText}</button>
            </div>
            </td>
            </tr>
            </tbody>
        </table>
            {/* &nbsp;&nbsp;<Link class="btn btn-primary" to="/home">Cancel</Link> */}
        </form>
    )
}
export default WaitlistForm;