import React, { useState } from "react";
import axios from "axios";

const Register = props => {
  const [confirmReg, setConfirmReg] = useState("");
  const [errs, setErrs] = useState({});

  // CHECK THIS OUT!!!!
  //    using a single state object to hold all data!
  const [ user, setUser ] = useState({
    username: "",
    email: "", 
    password: "", 
    confirmPassword: "",
  })

  // using a single function to update the state object
  //    we can use the input's name attribute as the key in to the object
  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }

  const register = e => {
    e.preventDefault();
    axios.post("/api/users/register", 
      user,  // the user state is already an object with the correct keys and values!
      {
        // this will force the sending of the credentials / cookies so they can be updated
        //    XMLHttpRequest from a different domain cannot set cookie values for their own domain 
        //    unless withCredentials is set to true before making the request
        withCredentials: true,
      }
      )
      .then(res => {
        // console.log(res.data);
        // when we successfully created the account, reset state for registration form
        //    We do this if we are NOT navigating automatically away from the page
        setUser({
          username: "",
          email: "", 
          password: "", 
          confirmPassword: "",
        })
        setConfirmReg("Thank you for Registering, you can now log in!");
        setErrs({});  // remember to reset errors state if it was successful
      })
      .catch((err) => {
        setErrs(err.response.data.errors);
      });
  };

  return (
    <div>
      <h3>Register</h3>
      {
        confirmReg ? 
          <h4 style={{color: "green"}}>{confirmReg}</h4>
          : null
      }
      <form class="form-horizontal" onSubmit={register}>
        <div class="form-group">
          <label class="col-sm-2 control-label">Username</label>
          {
            errs.username ? 
              <span className="error-text">{ errs.username.message }</span>
              : null
          }
          <div class="col-sm-2">
            <input
              class="form-control"
              type="text"
              name="username"
              value={user.username}
              placeholder="Username"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">Email</label>
          {
            errs.email? 
              <span className="error-text">{ errs.email.message }</span>
              : null
          }
          <div class="col-sm-2">
            <input
              class="form-control"
              type="email"
              name="email"
              value={user.email}
              placeholder="Email"
              onChange={ handleChange }
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">Password</label>
          {
            errs.password ? 
              <span className="error-text">{ errs.password.message }</span>
              : null
          }
          <div class="col-sm-2">
            <input
              class="form-control"
              type="password"
              name="password"
              value={user.password}
              placeholder="Password"
              onChange={ handleChange }
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">Confirm Password</label>
          {
            errs.confirmPassword? 
              <span className="error-text">{ errs.confirmPassword.message }</span>
              : null
          }
          <div class="col-sm-2">
            <input
              class="form-control"
              type="password"
              name="confirmPassword"
              value={user.confirmPassword}
              placeholder="Password"
              onChange={ handleChange }
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-offset-2 col-sm-10">
            <button 
              class="btn btn-primary"
              type="submit"
            >Register Me</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Register;
