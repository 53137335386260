import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { navigate } from '@reach/router';
import WaitlistForm from '../components/WaitlistForm';
import io from 'socket.io-client';

const Update = (props) => {
    const { id } = props;
    const [ waitlist, setWaitlist] = useState();
    const [loaded, setLoaded] = useState(false);
    const [errors, setErrors] = useState({});
    const [socket] = useState(() => io());

    useEffect(() => {
        axios.get('/api/waitlist/' + id, { withCredentials: true })
            .then(res => {
                setWaitlist(res.data);
                setLoaded(true);
            })
            .catch(() => {
                navigate("/admin");
            })
    }, [id]);

    // we need to clean up the socket ONLY when we "unmount" this component
    //      it needs to be able to stay connected regardless of the number of 
    //      newWaitList objects are added
    useEffect(() => {
        // close socket connection when component is done being used 
        return () => socket.disconnect();
    }, [socket])

    const updateWaitlist = waitlist => {
        waitlist.maskedName = waitlist.cname.charAt(0);
        waitlist.maskedPhone = waitlist.cphone.substr((waitlist.cphone.length-4),4);
        console.log(waitlist);
        axios.put('/api/waitlist/' + id, waitlist, { withCredentials: true })
            .then(res => {
                // notify the server through socket.io to share this create with other clients
                socket.emit("updated_one_waitlist", res.data);

                // moving the disconnect to a useEffect to also disconnect if someone just navigates away from the page
                // after emit, disconnect socket to free up resources
                // socket.disconnect();
                navigate("/admin")})
            .catch(err => {
                console.log(err.response.data.errors);
                setErrors(err.response.data.errors);
            })
    }

return (
    <div>
        <h3>Update a waitlist:</h3><hr/>
        { loaded && (
            <>
            <WaitlistForm onSubmitProp={ updateWaitlist }
                initialCname={ waitlist.cname }
                initialCphone={ waitlist.cphone }
                initialSize={ waitlist.size }
                buttonText="Update"
                errors={errors}/>
            </>
        )}
    </div>
    )
}
export default Update;
