import React from 'react';
import New from './New';
import Waitlist from '../components/Waitlist';

const Main = () => {

    return (
        <div>
            <New />
            <Waitlist/>
        </div>
    )
}
export default Main;